.responsive-btn {
  display: block !important;
}

.responsive-btn-icon {
  display: none !important;
}

@media screen and (max-width: 640px) {
  .responsive-btn {
    display: none !important;
  }

  .responsive-btn-icon {
    display: block !important;
  }
}
