.isMobile{
  display: none;
}

@media only screen and (max-width: 600px) {
  .istablet {
    display: none;
  }
  .isMobile{
    display: block;
    height: 100vh;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100vw;
    text-align: center;
  }
}

.table {
  width: 100%;
}

